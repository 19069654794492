<template>
  <FormulateForm
    #default="{ isLoading }"
    name="bidConfirmationStep2Form"
    @submit="submit"
  >
    <div class="tw-my-8 tw-p-4 tw-border-l">
      De transactie voor dossier <strong>{{ property.reference }}</strong> ontbreekt.
      Gelieve hieronder de intakegegevens in te vullen.
      Bij aanvaarding van het bod wordt een transactie aangemaakt waarbij de 'binnen' wordt ingevuld op basis van de ingegeven informatie.
    </div>
    <TransactionIntakeInputs
      ref="transactionIntakeInputs"
      :property="property"
    />
    <div>
      <h2 class="tw-mb-4">Betrokken kantoren en poules</h2>
      <TransactionInvolvementData
        ref="transactionInvolvements"
        context="bidRegistration"
        :property-id="property.id"
      />
    </div>
    <FormulateErrors class="tw-text-right" />
    <div class="tw-flex tw-justify-end">
      <FormulateInput
        type="submit"
        :disabled="isLoading"
      >
        <i
          :class="[
            'fas tw-mr-2',
            isLoading
              ? 'fa-spinner-third fa-spin'
              : 'fa-save'
          ]"
        />
        Bod aanvaard door eigenaar
      </FormulateInput>
    </div>
  </FormulateForm>
</template>

<script>
import TransactionIntakeInputs from '@/components/transactions/TransactionIntakeInputs'
import TransactionInvolvementData from '@/components/transactions/TransactionInvolvementData'

export default {
  name: 'PropertyBidConfirmStep2',
  components: { TransactionIntakeInputs, TransactionInvolvementData },
  props: {
    property: {
      type: Object
    }
  },
  data () {
    return {}
  },
  constants: {
    CURRENT_STEP: 2
  },
  computed: {},
  methods: {
    async submit (data) {
      const responseInputs = await this.$refs.transactionIntakeInputs.triggerSubmission()
      const responseInvolvements = await this.$refs.transactionInvolvements.triggerSubmission()
      this.$emit('finished', data, this.CURRENT_STEP)
      return [responseInputs, responseInvolvements]
    }
  }
}
</script>
