<template>
  <BaseModal ref="modal" title="Aanvaarding bod" :max-width="this.activeStep === 1 ? 'tw-max-w-xl' : 'tw-max-w-4xl'" @hide="hide">
    <div class="tw-p-4 tw-rounded tw-shadow-lg tw-mb-8 tw-w-full">
      <div class="formulate-label tw-mb-2">
        Bod: {{ currency(bid.amount) }}
      </div>
      <div>
        <span class="formulate-label">{{ bid.property_bid_contacts.length > 1 ? 'Kandidaat-kopers' : 'Kandidaat-koper' }}</span>
        <ul v-if="bid.property_bid_contacts.length">
          <li
            v-for="object in bid.property_bid_contacts"
            :key="object.id"
          >
            <router-link
              v-if="object.contact"
              :to="{ name: 'ContactDetails', params: { id: object.contact.id } }"
              target="_blank"
            >
              {{ object.contact.display_name }}
            </router-link>
          </li>
        </ul>
        <small v-if="bid.created_by" class="tw-block tw-mt-2">Geregistreerd door {{ bid.created_by.first_name }} {{ bid.created_by.last_name }}</small>
      </div>
    </div>

    <PropertyBidConfirmStep1
      v-show="activeStep === 1"
      v-bind="{
        bid,
        suspensiveConditions,
        shouldShowStep2
      }"
      @finished="moveForward"
    />
    <PropertyBidConfirmStep2
      v-show="activeStep === 2"
      :property="property"
      @finished="moveForward"
    />
  </BaseModal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { successModal } from '@/modalMessages'
import { parseFileObj, today, currency } from '@/utils/helpers'

import EventBus from '@/components/iam/bus'
import PropertyBidConfirmStep1 from '@/components/properties/PropertyBidConfirmStep1'
import PropertyBidConfirmStep2 from '@/components/properties/PropertyBidConfirmStep2'

import { getSuspensiveConditions } from '@/services/apiService'
import {
  updatePropertyBid,
  acceptPropertyBid
} from '@/services/properties'

export default {
  name: 'PropertyBidConfirmEditModal',
  props: {
    propertyId: {
      type: [Number, String],
      required: true
    }
  },
  constants: {
    today
  },
  components: {
    PropertyBidConfirmStep1,
    PropertyBidConfirmStep2
  },
  data () {
    return {
      activeStep: 1,
      bid: {
        property_bid_contacts: []
      },
      suspensiveConditions: [],
      shouldShowStep2: false,
      stepValues: {}
    }
  },
  computed: {
    ...mapGetters('properties', ['getPropertyById']),
    property () {
      return this.getPropertyById(this.propertyId)
    }
  },
  methods: {
    currency,
    ...mapActions('properties', ['loadProperty']),

    hide () {
      this.$emit('hideWithoutAcceptance')
    },

    async show (bid) {
      const { contact_confirmation_file, suspensive_condition, ...values } = bid
      this.bid = {
        contact_confirmation_file: [parseFileObj(contact_confirmation_file)],
        suspensive_condition: suspensive_condition?.id,
        ...values
      }
      const response = await this.fetchSuspensiveConditions()
      this.shouldShowStep2 = this.property.transaction === null // Todo: also show step 2 if intake information of transaction is not complete?
      this.$refs.modal.show()
      return response
    },
    async fetchSuspensiveConditions () {
      const response = await getSuspensiveConditions()
      const conditions = response.data?.results || []
      this.suspensiveConditions = conditions.map(({ id, name }) => {
        return { label: name, value: id }
      })
      return response
    },

    async moveForward (data, step) {
      if (data.edit_mode) {
        return await this.updateBid(data) // There's no step 2 when updating the bid information
      }
      this.$set(this.stepValues, `step${step}`, data)
      if (this.shouldShowStep2 && this.activeStep !== 2) {
        this.activeStep = 2
      } else {
        return await this.acceptBid(this.stepValues.step1)
      }
    },

    async updateBid (data) {
      const { id, accepted_date, contact_confirmation_file, owner_acceptance_file, ...payload } = data
      if (contact_confirmation_file?.length) {
        payload.contact_confirmation_file = contact_confirmation_file[0]?.[0]?.id
      }
      if (owner_acceptance_file?.length) {
        payload.owner_acceptance_file = owner_acceptance_file[0]?.[0]?.id
      }

      const response = await updatePropertyBid(this.propertyId, id, payload)
      this.$emit('bidUpdated')
      this.$set(this.bid, 'amount', data.amount)
      return response
    },
    async acceptBid (data) {
      const { id, accepted_date, owner_acceptance_file } = data
      const payload = { accepted_date }
      if (owner_acceptance_file?.length) {
        payload.owner_acceptance_file = owner_acceptance_file[0]?.[0]?.id
      }

      const response = await acceptPropertyBid(this.propertyId, id, payload)
      EventBus.$emit('bid-accepted')
      this.$refs.modal.hide()
      successModal('Bod succesvol geaccepteerd')
      this.$emit('bidAccepted')
      return response
    }
  }
}
</script>
