<template>
  <div>
    <div class="tw-mb-8">
      <h2>Commissie</h2>
      <div class="tw-my-4 tw-grid md:tw-grid-cols-3 tw-gap-4">
        <FormulateInput
          v-if="!isSales"
          v-model="formValues.commission_type_in"
          type="select"
          name="commission_type_in"
          label="Vooropgestelde commissie"
          :options="[
            { value: 6, label: 'Één maand huur' },
            { value: 17, label: '5% op jaarhuur' },
            { value: 15, label: '10% op jaarhuur' },
            { value: 9, label: '15% op jaarhuur' },
            { value: 12, label: 'Andere' }
          ]"
          validation="bail|required|number"
          :disabled="formValues.has_transaction"
          outer-class="tw-m-0"
          @change="updateCommission"
        />
        <FormulateInput
          v-if="isSales"
          v-model="formValues.commission_type"
          type="radio"
          name="commission_type"
          :options="{
            percentage: 'Percentage',
            fix: 'Fix'
          }"
          :input-class="['tw-my-4']"
          outer-class="tw-m-0"
          @input="handleCommissionTypeChange"
        />
        <div class="tw-grid md:tw-grid-cols-1">
          <FormulateInput
            v-if="isSales"
            v-model="formValues.commission_percentage_in"
            id="commission_percentage_in"
            type="number"
            name="commission_percentage_in"
            placeholder="Percentage"
            validation="bail|required|number|min:0"
            step=".01"
            min="0"
            lang="nl"
            :disabled="!disableCommissionField"
            outer-class="tw-m-0"
            :element-class="['tw-flex tw-items-center']"
            :input-class="['tw-rounded-tr-none tw-rounded-br-none']"
            @input="updateCommission"
          >
            <div slot="suffix" class="input-unit">
              <i class="fas fa-percent" />
            </div>
          </FormulateInput>
          <FormulateInput
            v-model="commissionFixIn"
            id="commission_fix_in"
            type="number"
            name="commission_fix_in"
            :label="isSales ? '' : 'Commissiewaarde'"
            :placeholder="isSales ? 'Fix' : 'Commissiewaarde'"
            validation="bail|required|number|min:0"
            step=".01"
            min="0"
            lang="nl"
            :disabled="disableCommissionField"
            outer-class="tw-m-0"
            :element-class="['tw-flex tw-items-center']"
            :input-class="['tw-rounded-tr-none tw-rounded-br-none']"
          >
            <div slot="suffix" class="input-unit">
              <i class="fas fa-euro-sign" />
            </div>
          </FormulateInput>
        </div>
      </div>
      <div v-if="formValues.commission_fix_in && isSales" class="tw-my-4 tw-grid md:tw-grid-cols-3 tw-gap-4">
        <p><b>Vooropgestelde commissie:</b> {{ currency(formValues.commission_fix_in) }}</p>
      </div>
    </div>
    <div>
      <h2>Mandaat</h2>
      <div class="tw-my-4 tw-grid md:tw-grid-cols-1 tw-gap-x-4">
        <FormulateInput
          type="radio"
          v-model="formValues.mandate_type"
          name="mandate_type"
          :options="{
            exclusive: 'Exclusieve opdracht',
            co_mandate: 'Co-mandaat',
            open_mandate: 'Open mandaat'
          }"
          validation="required"
          validation-name="Type mandaat"
          :input-class="['tw-my-1']"
          outer-class="tw-m-0 tw-mb-4"
          @input="handleMandateTypeChange"
        />
        <div v-if="formValues.mandate_type === 'co_mandate'">
          <fieldset class="tw-px-4 tw-border-gray-cc tw-rounded">
            <legend class="tw--mx-1 tw-my-0 tw-px-1 tw-w-auto tw-font-bold tw-text-sm tw-text-tg-color">Commissie co-mandaat</legend>
            <FormulateInput
              v-if="formValues.commission_type === 'percentage'"
              v-model="formValues.co_mandate_percentage_type_in"
              type="radio"
              name="co_mandate_percentage_type_in"
              :options="coMandatePercentageTypeOptions"
              :input-class="['tw-my-1']"
              outer-class="tw-m-0"
              @input="handleMandatePercentageTypeChange"
            />
            <div>
              <div v-if="formValues.co_mandate_percentage_type_in == '0'" class="tw-grid tw-grid-cols-2 tw-gap-x-4">
                <FormulateInput
                  id="co_mandate_collaborator_percentage_in"
                  v-model="formValues.co_mandate_collaborator_percentage_in"
                  type="number"
                  name="co_mandate_collaborator_percentage_in"
                  label="Verkopende makelaar"
                  placeholder="Percentage"
                  :validation="formValues.co_mandate_percentage_type_in == '0' ? 'bail|required|number|min:0|validMandatePercentage' : ''"
                  :validation-rules="{ validMandatePercentage }"
                  :validation-messages="{
                    validMandatePercentage: 'De som van percentages moet 100% zijn'
                  }"
                  step=".01"
                  min="0"
                  lang="nl"
                  outer-class="tw-my-4"
                  :element-class="['tw-flex tw-items-center']"
                  :input-class="['tw-rounded-tr-none tw-rounded-br-none']"
                >
                  <div slot="suffix" class="input-unit">
                    <i class="fas fa-percent" />
                  </div>
                </FormulateInput>
                <FormulateInput
                  id="co_mandate_other_percentage_in"
                  v-model="formValues.co_mandate_other_percentage_in"
                  type="number"
                  name="co_mandate_other_percentage_in"
                  label="Niet-verkopende makelaar"
                  placeholder="Percentage"
                  :validation="formValues.co_mandate_percentage_type_in == '0' ? 'bail|required|number|min:0|validMandatePercentage' : ''"
                  :validation-rules="{ validMandatePercentage }"
                  :validation-messages="{
                    validMandatePercentage: 'De som van percentages moet 100% zijn'
                  }"
                  step=".01"
                  min="0"
                  lang="nl"
                  outer-class="tw-my-4"
                  :element-class="['tw-flex tw-items-center']"
                  :input-class="['tw-rounded-tr-none tw-rounded-br-none']"
                >
                  <div slot="suffix" class="input-unit">
                    <i class="fas fa-percent" />
                  </div>
                </FormulateInput>
              </div>
              <div v-if="formValues.co_mandate_percentage_type_in == '1'" class="tw-grid tw-grid-cols-2 tw-gap-x-4">
                <FormulateInput
                  id="co_mandate_collaborator_percentage_in_commission_percentage"
                  v-model="formValues.co_mandate_collaborator_percentage_in_commission_percentage"
                  type="number"
                  name="co_mandate_collaborator_percentage_in_commission_percentage"
                  label="Verkopende makelaar"
                  placeholder="Percentage"
                  :validation="formValues.co_mandate_percentage_type == '1' ? 'bail|required|number|min:0|validMandatePercentageCommissionPercentage' : ''"
                  :validation-rules="{ validMandatePercentageCommissionPercentage }"
                  :validation-messages="{
                    validMandatePercentageCommissionPercentage: `De som van percentages moet ${formValues.commission_percentage_in}% zijn`
                  }"
                  step=".01"
                  min="0"
                  lang="nl"
                  outer-class="tw-my-4"
                  :element-class="['tw-flex tw-items-center']"
                  :input-class="['tw-rounded-tr-none tw-rounded-br-none']"
                >
                  <div slot="suffix" class="input-unit">
                    <i class="fas fa-percent" />
                  </div>
                </FormulateInput>
                <FormulateInput
                  id="co_mandate_other_percentage_in_commission_percentage"
                  v-model="formValues.co_mandate_other_percentage_in_commission_percentage"
                  type="number"
                  name="co_mandate_other_percentage_in_commission_percentage"
                  label="Niet-verkopende makelaar"
                  placeholder="Percentage"
                  :validation="formValues.co_mandate_percentage_type_in == '1' ? 'bail|required|number|min:0|validMandatePercentageCommissionPercentage' : ''"
                  :validation-rules="{ validMandatePercentageCommissionPercentage }"
                  :validation-messages="{
                    validMandatePercentageCommissionPercentage: `De som van percentages moet ${formValues.commission_percentage_in}% zijn`
                  }"
                  step=".01"
                  min="0"
                  lang="nl"
                  outer-class="tw-my-4"
                  :element-class="['tw-flex tw-items-center']"
                  :input-class="['tw-rounded-tr-none tw-rounded-br-none']"
                >
                  <div slot="suffix" class="input-unit">
                    <i class="fas fa-percent" />
                  </div>
                </FormulateInput>
              </div>
            </div>
            <p v-if="coMandateCommissions">
              Commissie verkopende makelaar: {{ currency(coMandateCommissions.co_mandate_collaborator_commission)}}
              <br>
              Commissie niet-verkopende makelaar: {{ currency(coMandateCommissions.co_mandate_other_commission)}}
            </p>
          </fieldset>

        </div>
      </div>
      <div class="tw-my-4 tw-grid md:tw-grid-cols-3 tw-gap-4">
        <FormulateInput
          type="date"
          v-model="formValues.mandate_signature_date"
          name="mandate_signature_date"
          label="Datum ondertekening opdracht"
          placeholder="YYYY-MM-DD"
          validation="bail|required|date:YYYY-MM-DD"
          outer-class="tw-m-0"
        />
        <FormulateInput
          type="date"
          v-model="formValues.mandate_start_date"
          name="mandate_start_date"
          label="Startdatum opdracht"
          placeholder="YYYY-MM-DD"
          validation="bail|required|date:YYYY-MM-DD"
          :disabled="formValues.has_transaction"
          outer-class="tw-m-0"
        />
        <FormulateInput
          type="date"
          v-model="formValues.mandate_end_date"
          name="mandate_end_date"
          label="Einddatum opdracht"
          placeholder="YYYY-MM-DD"
          validation="bail|optional|date:YYYY-MM-DD"
          outer-class="tw-m-0"
        />
        <FormulateInput
          type="file"
          v-model="formValues.mandate_file"
          name="mandate_file"
          label="Opdrachtdocument"
          :uploader="uploadFile"
          outer-class="formulate-input tw-my-0"
          wrapper-class="formulate-input-wrapper"
          :upload-area-mask-class="['tw-h-10 tw-mt-1']"
          :file-class="['tw-h-10 !tw-mt-1']"
          element-class="formulate-input-element formulate-input-element--file"
        >
          <template #file="context">
            <BaseFileUploadDisplay v-bind="context" />
          </template>
        </FormulateInput>
      </div>
    </div>
  </div>
</template>

<script>
import { currency, today } from '@/utils/helpers'
import { createPropertyFile, updateProperty, uploadFile } from '@/services/properties'
import { createTransaction } from '@/services/transactions'
import { errorModal } from '@/modalMessages'

const FILE_TYPES = {
  2: 53, // If transaction_type is 'Te koop'
  3: 52, // If transaction_type is 'Te huur'
  4: 76 // If transaction_type is 'Over te nemen'
}

export default {
  name: 'TransactionIntakeInputs',
  props: {
    property: {
      type: Object
    }
  },
  data () {
    return {
      formValues: {
        price: this.property.price,
        transaction_type: this.property.transaction_type,
        commission_percentage_in: this.property.transaction_type !== 3 ? 3.3 : 0,
        commission_type: this.property.transaction_type === 2 ? 'percentage' : 'fix',
        commission_fix_in: (this.property.price * 0.033).toFixed(2),
        mandate_start_date: this.property.mandate_start_date || today.date,
        mandate_type: 'exclusive'
      },
      commissionFixIn: 0,
      price: this.property.price
    }
  },
  computed: {
    propertyId () {
      return this.property.id
    },
    coMandatePercentageTypeOptions () {
      const options = [{ value: 0, label: 'Procentuele verdeling afgesproken ereloon bij opdracht' }]
      if (this.formValues.commission_type !== 'fix') {
        options.push({ value: 1, label: 'Procentuele verdeling commissiepercentage' })
      }
      return options
    },
    isSales () {
      return this.property.transaction_type === 2
    },
    disableCommissionField () {
      if (this.formValues.has_transaction) return true // Disabled
      if (this.isSales && this.formValues.commission_type === 'percentage') return true // Disabled
      return this.isSales ? false : this.formValues.commission_type_in !== '12' // Value received from form is string
    },
    coMandateCommissionsCommissionPercentageSplit () {
      return {
        co_mandate_collaborator_commission: (this.price * (this.formValues.co_mandate_collaborator_percentage_in_commission_percentage / 100)).toFixed(2),
        co_mandate_other_commission: (this.price * (this.formValues.co_mandate_other_percentage_in_commission_percentage / 100)).toFixed(2)
      }
    },
    coMandateCommissionsFixSplit () {
      return {
        co_mandate_collaborator_commission: (this.formValues.commission_fix_in * (this.formValues.co_mandate_collaborator_percentage_in / 100)).toFixed(2),
        co_mandate_other_commission: (this.formValues.commission_fix_in * (this.formValues.co_mandate_other_percentage_in / 100)).toFixed(2)
      }
    },
    coMandateCommissions () {
      if (this.formValues.co_mandate_percentage_type_in === '0' || this.formValues.commission_type === 'fix') return this.coMandateCommissionsFixSplit
      if (this.formValues.co_mandate_percentage_type_in === '1') return this.coMandateCommissionsCommissionPercentageSplit
      return null
    }
  },
  methods: {
    currency,
    updateCommission () {
      return this.isSales ? this.updateSalesCommission() : this.updateRentalCommission()
    },
    updateSalesCommission () {
      const {
        commission_percentage_in,
        commission_type,
        commission_fix_in
      } = this.formValues
      if (commission_type === 'percentage') {
        this.commissionFixIn = ((commission_percentage_in / 100) * this.price).toFixed(2)
      } else {
        this.commissionFixIn = commission_fix_in
      }
    },
    updateRentalCommission () {
      const { price, commission_type_in, commission_fix_in } = this.formValues
      const rentalPrice = parseFloat(price)
      if (!commission_type_in) return false
      /*
        Commission types ids:
        6: rental 1 month
        16: rental half month
        17: 5% of yearly rent
        9: 15% of yearly rent
        12: other
      */
      const commissionFixInValueMap = {
        6: rentalPrice, // Maandhuur = copy of the field Huurprijs
        16: rentalPrice / 2,
        17: 0,
        15: 0,
        9: 0,
        12: commission_fix_in // 0 and editable for the option "Andere"
      }
      const commissionFixValueIn = commissionFixInValueMap[parseInt(commission_type_in)]

      const commissionPercentageInMap = {
        6: 0,
        16: 0,
        17: 5,
        15: 10,
        9: 15,
        12: 0
      }
      const commissionPercentageIn = commissionPercentageInMap[parseInt(commission_type_in)]
      this.formValues.commission_percentage_in = commissionPercentageIn
      if (commissionFixValueIn) {
        this.formValues.commission_type = 'fix'
        this.commissionFixIn = commissionFixValueIn
      } else if (commissionPercentageIn) {
        this.formValues.commission_type = 'percentage'
        this.commissionFixIn = (rentalPrice * 12 * (commissionPercentageIn / 100)).toFixed(2)
      }
    },
    setDefaultCoMandatePercentageTypeIn () {
      if (this.formValues.mandate_type === 'co_mandate' && this.formValues.commission_type === 'fix') {
        this.$set(this.formValues, 'co_mandate_percentage_type_in', '0')
        this.handleMandatePercentageTypeChange()
      }
    },
    handleCommissionTypeChange () {
      this.updateCommission()
      this.setDefaultCoMandatePercentageTypeIn()
    },
    handleMandateTypeChange () {
      this.setDefaultCoMandatePercentageTypeIn()
      this.$set(this.formValues, 'co_mandate_collaborator_percentage_in', null)
      this.$set(this.formValues, 'co_mandate_other_percentage_in', null)
    },
    handleMandatePercentageTypeChange () {
      this.$set(this.formValues, 'co_mandate_other_percentage_in', null)
      this.$set(this.formValues, 'co_mandate_collaborator_percentage_in', null)
      this.$set(this.formValues, 'co_mandate_other_percentage_in_commission_percentage', null)
      this.$set(this.formValues, 'co_mandate_collaborator_percentage_in_commission_percentage', null)
    },
    validMandatePercentage ({ getFormValues }) {
      const { co_mandate_collaborator_percentage_in, co_mandate_other_percentage_in } = getFormValues()

      if (!co_mandate_collaborator_percentage_in || !co_mandate_other_percentage_in) return true
      const sum = parseInt(co_mandate_collaborator_percentage_in) + parseInt(co_mandate_other_percentage_in)
      return sum === 100
    },
    validMandatePercentageCommissionPercentage ({ getFormValues }) {
      const { co_mandate_collaborator_percentage_in_commission_percentage, co_mandate_other_percentage_in_commission_percentage } = getFormValues()

      if (!co_mandate_collaborator_percentage_in_commission_percentage || !co_mandate_other_percentage_in_commission_percentage) return true
      const sum = parseFloat(co_mandate_collaborator_percentage_in_commission_percentage) + parseFloat(co_mandate_other_percentage_in_commission_percentage)
      return sum === parseFloat(this.formValues.commission_percentage_in)
    },
    async createTransaction ({
      has_transaction,
      commission_type,
      commission_percentage_in,
      commission_type_in,
      commission_fix_in,
      mandate_type,
      co_mandate_percentage_type_in,
      co_mandate_collaborator_percentage_in,
      co_mandate_collaborator_percentage_in_commission_percentage,
      co_mandate_other_percentage_in,
      co_mandate_other_percentage_in_commission_percentage,
      mandate_signature_date
    }) {
      if (has_transaction) return false // We will not create a transaction if it already exists
      let commission_in = commission_fix_in
      let commission_external_amount_in = 0
      if (mandate_type === 'co_mandate') {
        commission_in = this.coMandateCommissions.co_mandate_collaborator_commission
        commission_external_amount_in = this.coMandateCommissions.co_mandate_other_commission
      }
      const payload = {
        property: this.propertyId,
        transaction_type: this.property.transaction_type,
        commission_type_in,
        commission_fix_value_in: commission_type === 'fix' ? commission_fix_in : 0,
        commission_percentage_in: commission_type === 'percentage' ? commission_percentage_in : 0,
        commission_fix_in: commission_in,
        commission_external_amount_in: commission_external_amount_in,
        mandate_type,
        co_mandate_percentage_type_in,
        co_mandate_collaborator_percentage_in: mandate_type === 'co_mandate' ? (co_mandate_collaborator_percentage_in || co_mandate_collaborator_percentage_in_commission_percentage) : null,
        co_mandate_other_percentage_in: mandate_type === 'co_mandate' ? (co_mandate_other_percentage_in || co_mandate_other_percentage_in_commission_percentage) : null,
        transaction_start_date: mandate_signature_date,
        concept_type: this.property.concept_type,
        should_create_transaction_offices: false
      }
      const response = await createTransaction(payload)
      return response
    },
    async updateProperty ({
      mandate_start_date,
      mandate_signature_date
    }) {
      const payload = {
        mandate_start_date,
        mandate_signature_date
      }
      const response = await updateProperty(this.propertyId, payload)
      return response.data
    },
    async triggerSubmission () {
      const propertyResponse = await this.updateProperty(this.formValues)
      const transactionResponse = await this.createTransaction(this.formValues)
      return [propertyResponse, transactionResponse]
    },
    async uploadFile (file, progress) {
      try {
        progress(0)
        const formData = new FormData()
        formData.append('file', file)
        progress(25)
        const { key, filename } = (await uploadFile(this.propertyId, formData)).data
        progress(50)
        const folders = await this.createDefaultFolderStructure()
        // Look for a folder called "Verkoop- verhuuropdracht"
        const folder_id = folders?.find(folder => folder.name === 'Verkoop- verhuuropdracht')?.id
        const payload = { key, filename, file_type: FILE_TYPES[this.property.transaction_type], folder_id }
        const response = await createPropertyFile(this.propertyId, payload)
        progress(100)
        return this.parseFileObj(response.data)
      } catch (error) {
        console.error(error)
        errorModal('Er ging iets mis bij het uploaden het bestand')
      }
    }
  }
}
</script>
