<template>
  <FormulateForm
    #default="{ isLoading }"
    v-model="values"
    name="confirmEditBidForm"
    @submit="submit"
  >
    <FormulateInput
      v-model="editMode"
      type="toggle"
      debounce
      name="edit_mode"
      label="Bewerking"
      outer-class="tw-my-8 tw-flex tw-flex-row-reverse tw-items-start"
    />
    <div class="md:tw-grid tw-grid-cols-2 tw-gap-x-4 tw-mt-4">
      <FormulateInput
        type="date"
        name="accepted_date"
        label="Datum aanvaarding"
        placeholder="YYYY-MM-DD"
        :value="today.date"
        :validation="editMode ? '' : 'bail|required|date:YYYY-MM-DD'"
        data-lpignore="true"
        outer-class="tw-mb-4"
      />
      <!-- tw-h override to make the input mask equal in height to the input field adjacent intut field -->
      <FormulateInput
        type="file"
        name="owner_acceptance_file"
        label="Bijlage aanvaarding eigenaar"
        :uploader="uploadFile"
        :upload-area-mask-class="['tw-h-[2.45rem] tw-mt-1 !tw-rounded-sm !tw-border-0.5']"
        :file-class="['tw-h-[2.45rem] !tw-mt-1']"
        element-class="formulate-input-element formulate-input-element--file tw-mb-0 !tw-max-w-full"
        wrapper-class="formulate-input-wrapper !tw-mb-0"
        outer-class="formulate-input !tw-font-sans !tw-mb-2 "
      >
        <template #file="context">
          <BaseFileUploadDisplay
            v-bind="context"
            :show-remove-file="true"
          />
        </template>
      </FormulateInput>
    </div>

    <transition name="fade" mode="out-in">
      <div v-if="editMode">
        <div class="tw-grid md:tw-grid-cols-2 tw-gap-4">
          <FormulateInput
            type="number"
            name="amount"
            label="Bod"
            placeholder="Bod"
            lang="nl"
            step="0.01"
            validation="bail|required|number"
            keep-model-data="true"
            data-lpignore="true"
            outer-class="tw-my-0"
          />

          <FormulateInput
            type="date"
            name="bid_date"
            label="Biedingsdatum"
            placeholder="YYYY-MM-DD"
            validation="bail|required|date:YYYY-MM-DD"
            keep-model-data="true"
            data-lpignore="true"
            outer-class="tw-my-0"
          />
          <FormulateInput
            type="date"
            name="valid_until_date"
            label="Geldig tot"
            placeholder="YYYY-MM-DD"
            :min="bid.bid_date"
            :validation="[
              ['bail'],
              ['optional'],
              ['date', 'YYYY-MM-DD'],
              ['after', bid.bid_date]
            ]"
            keep-model-data="true"
            data-lpignore="true"
            outer-class="tw-my-0"
          />
        </div>

        <FormulateInput
          type="textarea"
          name="comment"
          label="Opmerking"
          placeholder="Opmerking"
          keep-model-data="true"
          :input-class="['tw-h-24']"
        />

        <div class="tw-my-4 tw-grid md:tw-grid-cols-2 tw-gap-4">
          <FormulateInput
            type="select"
            name="suspensive_condition"
            label="Opschortende voorwaarde"
            placeholder="Selecteer een voorwaarde"
            :options="suspensiveConditions"
            keep-model-data="true"
            outer-class="tw-my-0"
          />
          <FormulateInput
            type="file"
            name="contact_confirmation_file"
            label="Bijlage uploaden (bieder)"
            keep-model-data="true"
            :uploader="uploadFile"
            :upload-area-mask-class="['tw-h-10 tw-mt-1 !tw-rounded-sm !tw-border-0.5']"
            :file-class="['tw-h-10 !tw-mt-1']"
            element-class="formulate-input-element formulate-input-element--file"
            wrapper-class="formulate-input-wrapper"
            outer-class="formulate-input !tw-my-0"
          >
            <template #file="context">
              <BaseFileUploadDisplay
                v-bind="context"
                :show-remove-file="true"
              />
            </template>
          </FormulateInput>
        </div>

        <FormulateInput
          type="checkbox"
          name="is_confirmed_in_writing"
          label="Dit bod is schriftelijk ontvangen"
          keep-model-data="true"
        />
      </div>
    </transition>

    <FormulateErrors class="tw-text-right" />

    <div class="tw-flex tw-justify-end">
      <FormulateInput
        type="submit"
        :disabled="isLoading"
        :input-class="[editMode ? 'tw-bg-gray-500' : '']"
      >
        <i
          :class="[
            'fas tw-mr-2',
            isLoading
              ? 'fa-spinner-third fa-spin'
              : submitIcon
          ]"
        />
        {{ submitLabel }}
      </FormulateInput>
    </div>
  </FormulateForm>
</template>

<script>
import { parseFileObj, today, currency } from '@/utils/helpers'
import {
  uploadFile,
  createPropertyFile
} from '@/services/properties'
export default {
  name: 'PropertyBidConfirmStep1',
  props: {
    bid: {
      type: Object,
      required: true
    },
    suspensiveConditions: {
      type: Array,
      required: true
    },
    shouldShowStep2: {
      type: Boolean
    }
  },
  data () {
    return {
      editMode: false,
      values: { ...this.bid }
    }
  },
  constants: {
    today,
    CURRENT_STEP: 1
  },
  computed: {
    submitIcon () {
      if (this.editMode) return 'fa-save'
      if (this.shouldShowStep2) return 'fa-arrow-right'
      return 'fa-badge-check'
    },
    submitLabel () {
      if (this.editMode) return 'Opslaan'
      if (this.shouldShowStep2) return 'Naar stap 2'
      return 'Bod aanvaard door eigenaar'
    }
  },
  methods: {
    currency,
    async uploadFile (file, progress) {
      try {
        progress(0)
        const formData = new FormData()
        formData.append('file', file)
        progress(33)
        const { key, filename } = (await uploadFile(this.propertyId, formData)).data
        progress(66)
        const payload = { key, filename }
        const response = await createPropertyFile(this.propertyId, payload)
        progress(99)
        const parsedFile = parseFileObj(response.data)
        progress(100)
        return [parsedFile]
      } catch (error) {
        this.$formulate.handle(error, 'confirmEditBidForm')
      }
    },
    submit (data) {
      this.editMode = false
      this.$emit('finished', data, this.CURRENT_STEP)
    }
  }
}
</script>
